import { createRouter, createWebHistory } from 'vue-router'
import { trackRouter } from 'vue-gtag-next'
import store from '@/store'

import About from '@/pages/About'
import Feed from '@/pages/Feed'
import Landing from '@/pages/Landing'
import Generic from '@/pages/Generic'
import Contact from '@/pages/Contact'
import Subscribe from '@/pages/Subscribe'
import FAQs from '@/pages/FAQs'
import TermsAndConditions from '@/pages/TermsAndConditions'
import CookiePolicy from '@/pages/CookiePolicy'
import PrivacyPolicy from '@/pages/PrivacyPolicy'
import SignIn from '@/pages/Account/SignIn'
import Register from '@/pages/Account/Register'
import NotFound from '@/pages/NotFound'
import PasswordReset from '@/pages/PasswordReset'
// import Maintenance from '@/pages/Maintenance'

import Circuits from '@/pages/Circuits/Listing'
import CircuitDetail from '@/pages/Circuits/Detail'

import Events from '@/pages/Events/Listing'
import EventDetail from '@/pages/Events/Detail'

import Organisers from '@/pages/Organisers/Listing'
import OrganiserDetail from '@/pages/Organisers/Detail'

import Albums from '@/pages/Albums/Listing'
import AlbumsOrganisers from '@/pages/Albums/Organisers'
import AlbumsMembers from '@/pages/Albums/Members'
import AlbumDetail from '@/pages/Albums/Detail'

import News from '@/pages/News/Listing'
import NewsDetail from '@/pages/News/Detail'

import Dashboard from '@/pages/Dashboard/Index'
import DashboardActivity from '@/pages/Dashboard/Activity'
import DashboardEvents from '@/pages/Dashboard/Events'
import DashboardPhotographs from '@/pages/Dashboard/Photographs'
import DashboardLaptimes from '@/pages/Dashboard/Laptimes'
import DashboardLeaderboards from '@/pages/Dashboard/Leaderboards'
import DashboardFriends from '@/pages/Dashboard/Friends'
import DashboardStats from '@/pages/Dashboard/Stats'
import DashboardVideos from '@/pages/Dashboard/Videos'
import DashboardCars from '@/pages/Dashboard/Cars'

import Account from '@/pages/Account/Account'
import AccountSettings from '@/pages/Account/Settings'
import AccountSupport from '@/pages/Account/Support'
import AccountPassword from '@/pages/Account/Password'

import Members from '@/pages/Members/Listing'
import MemberProfile from '@/pages/Members/Detail'
import MemberVerify from '@/pages/Members/Verify'

import Videos from '@/pages/Videos/Listing'

import LapTimes from '@/pages/LapTimes/Listing'

import Races from '@/pages/Races/Listing'
import RaceDetail from '@/pages/Races/Detail'

import UnAuthGuard from './unauth-guard'
import AuthGuard from './auth-guard'

const routes = [
  {
    path: '/',
    name: 'landing',
    component: Landing,
    meta: {
      title: 'Home',
      layout: 'Landing'
    },
    beforeEnter: AuthGuard
  },
  {
    path: '/home',
    name: 'home',
    component: Feed,
    meta: {
      layout: 'Sidebar',
      title: 'Home'
    },
    beforeEnter: UnAuthGuard
  },
  {
    path: '/events',
    name: 'events',
    component: Events,
    meta: {
      layout: 'Sidebar',
      title: 'Events'
    },
    beforeEnter: UnAuthGuard
  },
  {
    path: '/event/:slug',
    name: 'event',
    component: EventDetail,
    meta: {
      layout: 'Sidebar',
      title: 'Event'
    }
    // beforeEnter: UnAuthGuard
  },
  {
    path: '/races',
    name: 'races',
    component: Races,
    meta: {
      layout: 'Sidebar',
      title: 'Races'
    },
    beforeEnter: UnAuthGuard
  },
  {
    path: '/race/:slug',
    name: 'race',
    component: RaceDetail,
    meta: {
      layout: 'Sidebar',
      title: 'Race'
    },
    beforeEnter: UnAuthGuard
  },
  {
    path: '/circuits',
    name: 'circuits',
    component: Circuits,
    meta: {
      layout: 'Sidebar',
      title: 'Circuits'
    },
    beforeEnter: UnAuthGuard
  },
  {
    path: '/circuit/:slug',
    name: 'circuit',
    component: CircuitDetail,
    meta: {
      layout: 'Sidebar',
      title: 'Circuit'
    },
    beforeEnter: UnAuthGuard
  },
  {
    path: '/organisers',
    name: 'organisers',
    component: Organisers,
    meta: {
      layout: 'Sidebar',
      title: 'Organisers'
    },
    beforeEnter: UnAuthGuard
  },
  {
    path: '/organiser/:slug',
    name: 'organiser',
    component: OrganiserDetail,
    meta: {
      layout: 'Sidebar',
      title: 'Organiser'
    },
    beforeEnter: UnAuthGuard
  },
  {
    path: '/albums',
    // redirect: '/albums/organisers',
    name: 'albums',
    component: Albums,
    meta: {
      layout: 'Sidebar',
      title: 'Albums',
      fullWidth: true
    },
    beforeEnter: UnAuthGuard
    // children: [
    //   {
    //     path: 'organisers',
    //     component: AlbumsOrganisers,
    //     beforeEnter: UnAuthGuard,
    //     meta: {
    //       title: 'Albums - Organisers'
    //     }
    //   },
    //   {
    //     path: 'members',
    //     component: AlbumsMembers,
    //     beforeEnter: UnAuthGuard,
    //     meta: {
    //       title: 'Albums - Members'
    //     }
    //   }
    // ]
  },
  {
    path: '/album/:albumUid',
    name: 'album',
    component: AlbumDetail,
    meta: {
      layout: 'Sidebar',
      title: 'Album',
      fullWidth: true
    },
    beforeEnter: UnAuthGuard
  },
  {
    path: '/videos',
    name: 'videos',
    component: Videos,
    meta: {
      layout: 'Sidebar',
      title: 'Videos',
      fullWidth: true
    },
    beforeEnter: UnAuthGuard
  },
  {
    path: '/lap-times',
    name: 'lap times',
    component: LapTimes,
    meta: {
      layout: 'Sidebar',
      title: 'Lap times'
    },
    beforeEnter: UnAuthGuard
  },
  {
    path: '/news',
    name: 'News',
    component: News
  },
  {
    path: '/post/:slug',
    name: 'News Detail',
    component: NewsDetail
  },
  {
    path: '/about-us',
    name: 'About us',
    component: About,
    meta: {
      layout: 'narrow',
      title: 'About us'
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
    meta: {
      layout: 'narrow',
      title: 'Contact us'
    }
  },
  {
    path: '/subscribe',
    name: 'Subscribe',
    component: Subscribe,
    meta: {
      layout: 'narrow'
    }
  },
  {
    path: '/terms-and-conditions',
    name: 'Terms & Conditions',
    component: TermsAndConditions,
    meta: {
      layout: 'narrow'
    }
  },
  // {
  //   path: '/cookie-policy',
  //   name: 'Cookie Policy',
  //   component: CookiePolicy,
  //   meta: {
  //     layout: 'narrow'
  //   }
  // },
  {
    path: '/frequently-asked-questions',
    name: 'Frequently Asked Questions',
    component: FAQs
  },
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    component: PrivacyPolicy,
    meta: {
      layout: 'narrow'
    }
  },
  {
    path: '/accessibility',
    name: 'Accessibility',
    component: Generic,
    meta: {
      layout: 'narrow'
    }
  },
  {
    path: '/join',
    name: 'Register',
    component: Register,
    meta: {
      layout: 'Simple',
      title: 'Join'
    },
    beforeEnter: AuthGuard
  },
  {
    path: '/sign-in',
    name: 'Sign In',
    component: SignIn,
    meta: {
      layout: 'Simple',
      title: 'Sign in'
    },
    beforeEnter: AuthGuard,
    props: (route) => ({
      email: route.query.email,
      verified: route.query.verified
    })
  },
  {
    path: '/dashboard',
    redirect: '/dashboard/events',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      layout: 'Sidebar',
      title: 'Dashboard'
    },
    beforeEnter: UnAuthGuard,
    children: [
      // {
      //   path: 'activity',
      //   component: DashboardActivity,
      //   name: 'Activity',
      //   beforeEnter: UnAuthGuard,
      //   meta: {
      //     title: 'Dashboard - Activity'
      //   }
      // },
      {
        path: 'events',
        component: DashboardEvents,
        name: 'Events',
        beforeEnter: UnAuthGuard,
        meta: {
          title: 'Dashboard - Events'
        }
      },
      {
        path: 'photographs',
        component: DashboardPhotographs,
        name: 'Photographs',
        beforeEnter: UnAuthGuard,
        meta: {
          title: 'Dashboard - Photographs'
        }
      },
      {
        path: 'lap-times',
        component: DashboardLaptimes,
        name: 'Laptimes',
        beforeEnter: UnAuthGuard,
        meta: {
          title: 'Lap Dashboard - times'
        }
      },
      {
        path: 'leaderboards',
        component: DashboardLeaderboards,
        name: 'Leaderboards',
        beforeEnter: UnAuthGuard,
        meta: {
          title: 'Dashboard - Leaderboards'
        }
      },
      {
        path: 'friends',
        component: DashboardFriends,
        name: 'Friends',
        beforeEnter: UnAuthGuard,
        meta: {
          title: 'Dashboard - Friends'
        }
      },
      {
        path: 'stats',
        component: DashboardStats,
        name: 'Stats',
        beforeEnter: UnAuthGuard,
        meta: {
          title: 'Dashboard - Stats'
        }
      },
      {
        path: 'videos',
        component: DashboardVideos,
        name: 'Videos',
        beforeEnter: UnAuthGuard,
        meta: {
          title: 'Dashboard - Videos'
        }
      },
      {
        path: 'cars',
        component: DashboardCars,
        name: 'Cars',
        beforeEnter: UnAuthGuard,
        meta: {
          title: 'Dashboard - Cars'
        }
      }
    ]
  },
  {
    path: '/account',
    name: 'Account',
    component: Account,
    beforeEnter: UnAuthGuard,
    children: [
      {
        path: 'settings',
        name: 'Account Settings',
        component: AccountSettings,
        meta: {
          layout: 'Sidebar',
          title: 'Account Settings'
        },
        beforeEnter: UnAuthGuard
      },
      {
        path: 'support',
        component: AccountSupport,
        beforeEnter: UnAuthGuard,
        meta: {
          layout: 'Sidebar',
          title: 'Support'
        }
      },
      {
        path: 'password',
        component: AccountPassword,
        beforeEnter: UnAuthGuard,
        meta: {
          layout: 'Sidebar',
          title: 'Password'
        }
      }
    ]
  },
  {
    path: '/password-reset',
    name: 'Password Reset',
    component: PasswordReset,
    meta: {
      layout: 'Simple',
      title: 'Password reset'
    },
    beforeEnter: AuthGuard,
    props: (route) => ({
      code: parseInt(route.query.code),
      email: route.query.email,
      username: route.query.username
    })
  },
  {
    path: '/members',
    component: Members,
    meta: {
      layout: 'Sidebar',
      title: 'Members',
      fullWidth: true
    },
    beforeEnter: UnAuthGuard
  },
  {
    path: '/verify-account',
    component: MemberVerify,
    meta: {
      title: 'Verify Account',
      layout: 'Simple'
    },
    beforeEnter: AuthGuard,
    props: (route) => ({
      code: route.query.code,
      username: route.query.username,
      clientId: route.query.clientId,
      region: route.query.region,
      email: route.query.email
    })
  },
  {
    path: '/:username',
    component: MemberProfile,
    meta: {
      title: 'Profile',
      layout: 'Sidebar',
      fullWidth: true
    }
  },
  {
    path: '/404',
    name: 'NotFound',
    component: NotFound,
    meta: {
      title: 'Page not found'
    }
  },
  {
    path: '/:notFound(.*)',
    component: NotFound,
    meta: {
      title: 'Page not found'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash
      }
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title)

  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags)

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags)

  if (nearestWithTitle) {
    document.title = `${nearestWithTitle.meta.title} | Tracklimit`
  } else if (previousNearestWithMeta) {
    document.title = `${previousNearestWithMeta.meta.title} | Tracklimit`
  }

  store.dispatch('setProfilePanelDisplay', false)
  store.dispatch('setNotificationsDisplay', false)
  store.dispatch('setSidebarDisplay', false)

  if (from.path !== to.path) {
    store.dispatch('setPanel', null)
    store.dispatch('notifications/getNotifications')
  }

  if ((!!from.query.u && from.query.u !== to.query.u) || (from.path !== to.path && !!!from.query.u))
    store.dispatch('setOverlay', null)

  next()
})

trackRouter(router)

export default router

export default {
  async getFormats(context, payload) {
    if (context.getters.getLastFetch) {
      return
    }

    const currDateTime = new Date().getTime()

    try {
      const formats = await this._modules.root._rawModule.methods.apiRequest('/format/formats', null, null, {
        Authorization: this.getters.getJWT
      })

      context.commit('SET_FORMATS', formats)
      context.commit('SET_LAST_FETCH', currDateTime)

      return
    } catch (e) {
      if (e) console.log('Error getting formats', e)
    }
  }
}

export default {
  getLoggedInUser(state) {
    return state.user
  },
  getIsAuthenticated(state) {
    return !!state.user
  },
  getAwaitingAuthState(state) {
    return state.awaitingAuthCheck
  }
}

import Vuex from 'vuex'
import axios from 'axios'
import authModule from './modules/auth/index.js'
import circuitsModule from './modules/circuits/index.js'
import eventsModule from './modules/events/index.js'
import feedModule from './modules/feed/index.js'
import layoutsModule from './modules/layouts/index.js'
import formatsModule from './modules/formats/index.js'
import organisersModule from './modules/organisers/index.js'
import notificationsModule from './modules/notifications/index.js'

export default Vuex.createStore({
  modules: {
    auth: authModule,
    circuits: circuitsModule,
    events: eventsModule,
    feed: feedModule,
    formats: formatsModule,
    layouts: layoutsModule,
    organisers: organisersModule,
    notifications: notificationsModule
  },
  state() {
    return {
      displayProfileMenu: false,
      displayNotifications: false,
      displaySidebar: false,
      dialog: null,
      overlay: null,
      panel: null,
      panelStatus: null,
      isLoading: null,
      jwt: null,
      today: null,
      isBottom: null,
      isTrackingScrollPos: false
    }
  },
  getters: {
    getJWT(state) {
      return state.jwt
    },
    getProfileMenuDisplay(state) {
      return state.displayProfileMenu
    },
    getSidebarDisplay(state) {
      return state.displaySidebar
    },
    getNotificationsDisplay(state) {
      return state.displayNotifications
    },
    getDialog(state) {
      return state.dialog
    },
    getOverlay(state) {
      return state.overlay
    },
    getPanel(state) {
      return state.panel
    },
    getPanelStatus(state) {
      return state.panelStatus
    },
    getLoadingStatus(state) {
      return state.isLoading
    },
    getToday(state) {
      return state.today
    },
    getIsBottom(state) {
      return state.isBottom
    },
    getIsTrackingScrollPos(state) {
      return state.isTrackingScrollPos
    }
  },
  actions: {
    setJWT(context, payload) {
      context.commit('SET_JWT', payload)
    },
    setProfilePanelDisplay(context, payload) {
      context.commit('SET_PROFILE_MENU_DISPLAY', payload)
    },
    setNotificationsDisplay(context, payload) {
      context.commit('SET_NOTIFICATIONS_DISPLAY', payload)
    },
    setSidebarDisplay(context, payload) {
      context.commit('SET_SIDEBAR_DISPLAY', payload)
    },
    setDialog(context, payload) {
      context.commit('SET_DIALOG', payload)
    },
    setOverlay(context, payload) {
      context.commit('SET_OVERLAY', payload)
    },
    setPanel(context, payload) {
      context.commit('SET_PANEL', payload)
    },
    setPanelStatus(context, payload) {
      context.commit('SET_PANEL_STATUS', payload)
    },
    setLoadingStatus(context, payload) {
      context.commit('SET_LOADING_STATUS', payload)
    },
    setToday(context, payload) {
      context.commit('SET_TODAY', payload)
    },
    setIsBottom(context, payload) {
      context.commit('SET_IS_BOTTOM', payload)
    },
    setIsTrackingScrollPos(context, payload) {
      context.commit('SET_IS_TRACKING_SCROLL_POS', payload)
    },
    async sendEmail(context, payload) {
      try {
        const res = await axios.post(`${process.env.VUE_APP_API_URL}/helper/sendEmail`, payload)

        this.dispatch('setDialog', {
          title: 'Message Sent!',
          content: "<p>Thanks for the message. We'll be in touch soon!</p>",
          ctaTitle: 'Close',
          confirmed: true
        })
      } catch (e) {
        this.dispatch('setDialog', {
          title: 'Error',
          content: "<p>Sorry we couldn't send your message. Please try again.</p>",
          ctaTitle: 'Close',
          error: true
        })
      }
    }
  },
  mutations: {
    SET_JWT(state, payload) {
      state.jwt = payload
    },
    SET_PROFILE_MENU_DISPLAY(state, payload) {
      state.displayProfileMenu = payload
    },
    SET_NOTIFICATIONS_DISPLAY(state, payload) {
      state.displayNotifications = payload
    },
    SET_SIDEBAR_DISPLAY(state, payload) {
      state.displaySidebar = payload
    },
    SET_DIALOG(state, payload) {
      state.dialog = payload
    },
    SET_OVERLAY(state, payload) {
      state.overlay = payload
    },
    SET_PANEL(state, payload) {
      state.panel = payload
    },
    SET_PANEL_STATUS(state, payload) {
      state.panelStatus = payload
    },
    SET_LOADING_STATUS(state, payload) {
      state.isLoading = payload
    },
    SET_TODAY(state, payload) {
      state.today = payload
    },
    SET_IS_BOTTOM(state, payload) {
      state.isBottom = payload
    },
    SET_IS_TRACKING_SCROLL_POS(state, payload) {
      state.isTrackingScrollPos = payload
    }
  },
  methods: {
    async apiRequest(endpoint, data = {}, params = {}, headers = {}, method = 'GET', rootUrl = false) {
      try {
        const res = await axios({
          method: method,
          url: !rootUrl ? process.env.VUE_APP_API_URL + endpoint : endpoint,
          data: data,
          params: params,
          headers: headers
        })

        if (res.status !== 200) {
          const error = {
            response: {
              data: res.data ? res.data : null,
              status: res.status
            }
          }

          throw error
        }

        return res.data
      } catch (e) {
        throw e.response
      }
    }
  }
})

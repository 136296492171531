export default {
  async getCircuits(context, payload) {
    if (context.getters.getLastFetch) {
      return
    }

    const currDateTime = new Date().getTime()

    try {
      const circuits = await this._modules.root._rawModule.methods.apiRequest('/circuit/circuits', null, null, {
        Authorization: this.getters.getJWT
      })

      context.commit('SET_CIRCUITS', circuits)
      context.commit('SET_LAST_FETCH', currDateTime)

      return
    } catch (e) {
      if (e) console.log('Error getting circuits', e)
    }
  }
}

export default {
  SET_FEED(state, payload) {
    state.feed = payload
  },
  SET_TOTAL(state, payload) {
    state.total = payload
  },
  SET_PAGE(state, payload) {
    state.page = payload
  },
  SET_VISIBLE(state, payload) {
    state.visible = payload
  }
}

import { Auth } from 'aws-amplify'

export default (to, from, next) => {
  Auth.currentAuthenticatedUser()
    .then((data) => {
      next({
        path: '/home'
      })
    })
    .catch(() => {
      next()
    })
}

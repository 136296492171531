import { Auth } from 'aws-amplify'

export default (to, from, next) => {
  Auth.currentAuthenticatedUser()
    .then((data) => {
      next()
    })
    .catch((error) => {
      if (to.fullPath !== '/') {
        next({
          path: '/sign-in',
          query: { to: to.fullPath }
        })
      } else {
        next({
          path: '/sign-in'
        })
      }
      return error
    })
}

export default {
  async getEvents(context, payload) {
    if (!!payload && !!payload.refresh && context.getters.getLastFetch) return

    const currDateTime = new Date().getTime()
    const options = {
      start: payload && payload.start ? payload.start : '2000-01-01',
      end: payload && payload.end ? payload.end : `${new Date().getFullYear()}-12-31`,
      all: payload && payload.all
    }

    if (payload && payload.friends) {
      options.friends = true
    }

    try {
      const events = await this._modules.root._rawModule.methods.apiRequest('/event/events', null, options, {
        Authorization: this.getters.getJWT
      })

      context.commit('SET_EVENTS', events)
      context.commit('SET_LAST_FETCH', currDateTime)
    } catch (e) {
      if (e) console.log('Error getting events', e)
    }
  }
}

import { Auth } from 'aws-amplify'

export default {
  async getNotifications(context, payload) {
    const currentSession = await Auth.currentSession()
    const idToken = currentSession.getIdToken()
    const currentUserUid = currentSession.idToken.payload.sub

    try {
      const notifications = await this._modules.root._rawModule.methods.apiRequest(
        `/user/${currentUserUid}/notifications`,
        null,
        null,
        { Authorization: idToken.jwtToken },
        'GET'
      )

      context.commit('SET_NOTIFICATIONS', notifications)
    } catch (e) {
      console.log(e)
    }
  }
}

import { Auth } from 'aws-amplify'
import router from '@/router'
import axios from 'axios'

export default {
  async signUserUp(context, payload) {
    try {
      const username = payload.email
      const password = payload.password

      await Auth.signUp({ username, password })

      this.dispatch('setDialog', {
        title: 'Success',
        content: 'Please check your email to confirm your account.',
        ctaTitle: 'Close',
        confirmed: true
      })
    } catch (e) {
      this.dispatch('setDialog', {
        title: 'Error',
        content: `<p>${e.message}</p>`,
        ctaTitle: 'Try again',
        error: true
      })
    }
  },
  async signUserIn(context, payload) {
    try {
      await Auth.signIn(payload.email, payload.password)
      await this.dispatch('auth/autoSignIn')

      if (router.currentRoute.value.query.to) {
        const to = router.currentRoute.value.query.to
        const obj = {
          path: to,
          query: {}
        }

        if (to.includes('p=true')) obj.query.p = true

        if (to.includes('u=')) {
          const u = to.split('u=')

          obj.query.u = u[1]
        }

        if (to.includes('organiser=')) {
          const organiser = to.split('organiser=')

          obj.query.organiser = decodeURIComponent(organiser[1].replace(/\+/g, ' '))
        }

        router.push(obj)
      } else {
        router.push({ name: 'landing' })
      }
    } catch (e) {
      let message
      let ctaTitle

      if (e.code === 'UserNotFoundException') {
        message =
          '<p>The email address & password combination has not been recognised. Please try again or you can <a class="text-blue-500 border-b border-blue-300" href="/password-reset">reset your password</a></p>'
        ctaTitle = 'Try again'
      } else if (e.code === 'UserNotConfirmedException') {
        message = '<p>Please confirm your account by clicking the link sent to you in an email.</p>'
        ctaTitle = 'Close'
      } else if (e.code === 'UsernameExistsException') {
        message = '<p>Looks like you already have an account, try <a href="/sign-in">signing in</a></p>'
        ctaTitle = 'Close'
        // } else if (e.code === 'NotAuthorizedException') {
        //   message = `<p>Your account has been disabled, please <a href="${process.env.VUE_APP_API_URL}/contact">contact</a> us for more information.</p>`
      } else {
        message = e.message
      }

      this.dispatch('setDialog', {
        title: 'Error',
        content: message,
        ctaTitle: typeof ctaTitle !== 'undefined' ? ctaTitle : 'Try again',
        error: true
      })
    }
  },
  async signOut(context) {
    try {
      await Auth.signOut()
      this.dispatch('auth/setLoggedInUser', null)
      router.push({ path: '/sign-in' })
    } catch (e) {
      this.dispatch('setDialog', {
        title: 'Error',
        content: `<p>${e.message}</p>`,
        ctaTitle: 'Close',
        error: true
      })
    }
  },
  async autoSignIn(context, payload) {
    const currentSession = await Auth.currentSession()
    const idToken = currentSession.getIdToken()

    try {
      const res = await axios.get(`${process.env.VUE_APP_API_URL}/user/account`, {
        headers: {
          Authorization: idToken.jwtToken
        }
      })
      const userData = res.data
      const uuidV4Regex = /^[A-F\d]{8}-[A-F\d]{4}-4[A-F\d]{3}-[89AB][A-F\d]{3}-[A-F\d]{12}$/i

      if (!userData) return

      userData.id = currentSession.idToken.payload.sub
      userData.email = currentSession.idToken.payload.email

      if (uuidV4Regex.test(userData.username))
        this.dispatch('setDialog', {
          title: 'Welcome to Tracklimit',
          branding: true,
          content: "Now it's time to set your profile name, upload a cover photo and start using Tracklimit.",
          ctaTitle: 'Complete profile',
          action: '/account/settings',
          disableTimer: true
        })

      this.dispatch('setJWT', idToken.jwtToken)
      this.dispatch('auth/setLoggedInUser', userData)
    } catch (e) {
      console.debug(`Failed to get users data. err: ${e}`)
    }

    this.dispatch('auth/awaitingAuthCheck', false)
  },
  async changePassword(context, payload) {
    try {
      const user = await Auth.currentAuthenticatedUser()
      await Auth.changePassword(user, payload.password, payload.newPassword)

      this.dispatch('setDialog', {
        title: 'Password updated',
        content: '<p>Your password has been changed.</p>',
        ctaTitle: 'Close',
        action: '/account/settings',
        confirmed: true
      })
    } catch (e) {
      let message
      console.log('Error', e)

      if (e.code === 'NotAuthorizedException') {
        message = '<p>Your current password is incorrect</p>'
      } else if (e.code === 'LimitExceededException') {
        message = "<p>Sorry you've entered an incorrect password too many times. Please try again later.</p>"
      }

      this.dispatch('setDialog', {
        title: 'Error',
        content: message !== '' ? message : "<p>Sorry we couldn't update your password. Please try again.</p>",
        ctaTitle: 'Close',
        error: true
      })
    }
  },
  async requestPasswordResetLink(context, payload) {
    const message =
      '<p>If an account associated with this email has been found, a password reset email has been sent.</p>'

    try {
      await Auth.forgotPassword(payload.email)

      this.dispatch('setDialog', {
        title: 'Password reset',
        content: message,
        ctaTitle: 'Close',
        confirmed: true
      })
    } catch (e) {
      this.dispatch('setDialog', {
        title: 'Password reset',
        content: message,
        ctaTitle: 'Close',
        confirmed: true
      })
    }
  },
  async createNewPassword(context, payload) {
    try {
      await Auth.forgotPasswordSubmit(payload.email, payload.code.toString(), payload.password)

      this.dispatch('setDialog', {
        title: 'Password reset',
        content: '<p>Your password has been changed, you can now sign in.</p>',
        action: '/sign-in',
        ctaTitle: 'Close',
        confirmed: true
      })
    } catch (e) {
      this.dispatch('setDialog', {
        title: 'Password reset',
        content: e.message,
        ctaTitle: 'Close',
        error: true
      })
    }
  },
  async updateAccount(context, payload) {
    const currentSession = await Auth.currentSession()
    const idToken = currentSession.getIdToken()
    const currentUser = this.getters['auth/getLoggedInUser']
    const currentUserUid = currentSession.idToken.payload.sub

    this.isLoading = true

    try {
      await this._modules.root._rawModule.methods.apiRequest(
        `/user/${currentUserUid}`,
        payload,
        null,
        { Authorization: idToken.jwtToken },
        'PUT'
      )

      for (const [key, value] of Object.entries(payload)) {
        currentUser[key] = value
      }

      context.commit('SET_LOGGED_IN_USER', currentUser)

      this.dispatch('setDialog', {
        title: 'Details updated',
        content: '<p>Your details have been saved.</p>',
        ctaTitle: 'Close',
        confirmed: true
      })
      window.scrollTo(0, 0)
    } catch (e) {
      this.dispatch('setDialog', {
        title: 'Error',
        content: e.data.includes('users_username_uindex')
          ? '<p>Sorry that username has been taken</p>'
          : '<p>There was an issue whilst saving your changes.</p>',
        ctaTitle: 'Try again',
        error: true
      })
    }
  },
  setLoggedInUser(context, payload) {
    context.commit('SET_LOGGED_IN_USER', payload)
  },
  awaitingAuthCheck(context, payload) {
    context.commit('SET_AWAITING_AUTH_CHECK', payload)
  }
}

export default {
  async getOrganisers(context, payload) {
    if (context.getters.getLastFetch) {
      return
    }

    const currDateTime = new Date().getTime()

    try {
      const organisers = await this._modules.root._rawModule.methods.apiRequest('/organiser/organisers', null, null, {
        Authorization: this.getters.getJWT
      })

      context.commit('SET_ORGANISERS', organisers)
      context.commit('SET_LAST_FETCH', currDateTime)

      return
    } catch (e) {
      if (e) console.log('Error getting organisers', e)
    }
  }
}

import { Auth } from 'aws-amplify'

export default {
  async getFeed(context, payload) {
    const currentSession = await Auth.currentSession()
    const idToken = currentSession.getIdToken()
    const currentUserUid = currentSession.idToken.payload.sub

    try {
      const feed = await this._modules.root._rawModule.methods.apiRequest(
        `/feed`,
        null,
        {
          page: payload.page
        },
        { Authorization: idToken.jwtToken }
      )
      const currentFeed = this.getters['feed/getFeed']

      if (payload.page === 1) context.commit('SET_TOTAL', parseInt(feed.total))
      context.commit('SET_FEED', payload.page > 1 ? currentFeed.concat(feed.items) : feed.items)
      context.commit('SET_PAGE', payload.page + 1)
      context.commit('SET_VISIBLE', feed.total > 25 ? 25 * payload.page : feed.total)
    } catch (e) {
      console.log('Error getting feed', e)
    }
  }
}
